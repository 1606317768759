/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { getItem } from "../common/reusable";

// Lazy load components
const Header = lazy(() => import("../common/Header"));
const Header2 = lazy(() => import("../common/Header2"));
const Section = lazy(() => import("./Section/Section"));
const Footer2 = lazy(() => import("../common/Footer2"));

function Home() {
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [url, setUrl] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedUrl = localStorage.getItem("url")?.replaceAll('"', "");
    setIsTokenValid(token && storedUrl);
    setUrl(storedUrl);

    if (token && (storedUrl === "/startup-profile" || storedUrl === "/investor-dashboard")) {
      return;
    } else if (storedUrl) {
      navigate(storedUrl);
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Invest in Indian Startups | Udharaa - Your Startup Funding Platform</title>
        <link rel="canonical" href="https://udharaa.com/" />
        <meta name="description" content="Udharaa connects investors with promising Indian startups. Explore funding, angel investors, and diverse investment opportunities. Start your investment journey today!" />
      </Helmet>

      <Suspense fallback={<div>Loading...</div>}>
        {isTokenValid ? <Header2 /> : <Header />}
        <Section />
        <Footer2 />
      </Suspense>
    </>
  );
}

export default Home;
