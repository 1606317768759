import "./App.css";
import {
  signup,
  home,
  signupstep2,
  signupstep3,
  signupstep4,
  signupstep5,
  signupstep6,
  signupstep7,
  signupstep8,
  signupstep9,
  welcome,
  investorsignup,
  investorsignupstep1,
  investorsignupstep2,
  investorsignupstep3,
  investorsignupstep4,
  welcomeinvestor,
  investordashboard,
  login,
  forgetpassword,
  passwordchange,
  passwordchangescucess,
  investorviewdetails,
  investorprivacy,
  startupprofile,
  watchlist,
  accountsetting,
  investorprofile,
  terms,
  privacypolicy,
  support,
  investmenthistory,
  aboutus,
  contactus,
  messenger,
  investorprivacyview,
} from "./Routes/Routes";
import Home from "./maincomponent/Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import PrivateRoutes from "./Routes/PrivateRoutes";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { lazy, Suspense } from "react";
import Loader from "./common/Loader";

const Signup = lazy(() => import("./maincomponent/Signup/Signup"));
const InvestorProfile = lazy (() => import("./maincomponent/InvestorDashboard/InvestorProfile"))
const Support = lazy (() => import("./maincomponent/terms&privacy/Support"))
const InvestmentHistory = lazy (() => import("./maincomponent/InvestorDashboard/InvestmentHistory"))
const AboutUs = lazy (() => import("./maincomponent/terms&privacy/AboutUs"))
const ContactUs = lazy (() => import("./maincomponent/terms&privacy/ContactUs"))
const CustomMessenger = lazy (() => import("./Components/CustomMessenger/CustomMessenger"))
const InvestorPrivacydownload = lazy (() => import("./maincomponent/InvestorDashboard/InvestorPrivaydownload"))
const SignupStep2 = lazy(() => import("./maincomponent/Signup/Signupstep2"))
const SignupStep3 = lazy(() => import("./maincomponent/Signup/Signupstep3"))
const SignupStep4 = lazy(() => import("./maincomponent/Signup/Signupstep4"))
const SignupStep6 = lazy(() => import("./maincomponent/Signup/Signupstep6"))
const SignupStep5 = lazy(() => import("./maincomponent/Signup/Signupstep5"))
const SignupStep7 = lazy(() => import("./maincomponent/Signup/Signupstep7"))
const SignupStep8 = lazy(() => import("./maincomponent/Signup/Signupstep8"))
const SignupStep9 = lazy(() => import("./maincomponent/Signup/Signupstep9"))
const Welcome = lazy(() => import("./maincomponent/Welcome/Welcome"))
const InvestorSignup = lazy(() => import("./maincomponent/InvestorSignup/InvestorSignup"))
const InvestorSignupStep1 = lazy(() => import("./maincomponent/InvestorSignup/InvestorSignupstep1"))
const InvestorSignupStep2 = lazy(() => import("./maincomponent/InvestorSignup/InvestorSignupstep2"))
const WelcomeInvestor = lazy(() => import("./maincomponent/Welcome/WelcomeInvestor"))
const InvestorSignupStep3 = lazy(() => import("./maincomponent/InvestorSignup/InvestorSignupstep3"))
const InvestorSignupStep4 = lazy(() => import("./maincomponent/InvestorSignup/InvestorSignupstep4"))
const InvestorDashboard = lazy(() => import("./maincomponent/InvestorDashboard/InvestorDashboard"))
const Login = lazy(() => import("./maincomponent/Login/Login"))
const ForgetPassword = lazy(() => import("./maincomponent/Login/ForgetPassword"))
const PasswordChange = lazy(() => import("./maincomponent/Login/PasswordChange"))
const PasswordChangeScucess = lazy(() => import("./maincomponent/Login/PasswordChangeScucess"))
const InvestorViewDetails = lazy(() => import("./maincomponent/InvestorDashboard/InvestorViewDetails"))
const InvestorPrivacy = lazy(() => import("./maincomponent/InvestorDashboard/InvestorPrivacy"))
const StartupProfile = lazy(() => import("./maincomponent/StartupDashboard/StartupProfile"))
const Watchlist = lazy(() => import("./maincomponent/InvestorDashboard/Watchlist"))
const AccountSetting = lazy(() => import("./maincomponent/InvestorDashboard/AccountSetting"))
const Terms = lazy(() => import("./maincomponent/terms&privacy/terms"))
const Privacy = lazy(() => import("./maincomponent/terms&privacy/privacy"))
const NotFound = lazy(() => import("./common/404"))

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* Public Routes  */}
            <Route path={home} element={<Home />}></Route>
            <Route path={signup} element={<Signup />}></Route>
            <Route exact path="/linkedin" element={<LinkedInCallback />} />
            <Route path={signupstep2} element={<SignupStep2 />}></Route>
            <Route path={signupstep3} element={<SignupStep3 />}></Route>
            <Route path={signupstep4} element={<SignupStep4 />}></Route>
            <Route path={signupstep5} element={<SignupStep5 />}></Route>
            <Route path={signupstep6} element={<SignupStep6 />}></Route>
            <Route path={signupstep7} element={<SignupStep7 />}></Route>
            <Route path={signupstep8} element={<SignupStep8 />}></Route>
            <Route path={signupstep9} element={<SignupStep9 />}></Route>
            <Route path={investorsignup} element={<InvestorSignup />}></Route>
            <Route
              path={investorsignupstep1}
              element={<InvestorSignupStep1 />}
            ></Route>
            <Route
              path={investorsignupstep2}
              element={<InvestorSignupStep2 />}
            ></Route>
            <Route
              path={investorsignupstep3}
              element={<InvestorSignupStep3 />}
            ></Route>
            <Route
              path={investorsignupstep4}
              element={<InvestorSignupStep4 />}
            ></Route>
            <Route path={login} element={<Login />}></Route>
            <Route path={forgetpassword} element={<ForgetPassword />}></Route>
            <Route path={passwordchange} element={<PasswordChange />}></Route>
            <Route path={terms} element={<Terms />}></Route>
            <Route path={privacypolicy} element={<Privacy />}></Route>
            <Route path="*" element={<NotFound />}></Route>
            <Route path={support} element={<Support />}></Route>
            <Route path={aboutus} element={<AboutUs />}></Route>
            <Route path={contactus} element={<ContactUs />}></Route>
            <Route
              path={passwordchangescucess}
              element={<PasswordChangeScucess />}
            ></Route>
            {/* Private Routes  */}

            <Route element={<PrivateRoutes />}>
              <Route path={welcome} element={<Welcome />}></Route>
              <Route path={messenger} element={<CustomMessenger />}></Route>
              <Route path={welcomeinvestor} element={<WelcomeInvestor />}></Route>
              <Route
                path={investordashboard}
                element={<InvestorDashboard />}
              ></Route>
              <Route
                path={passwordchangescucess}
                element={<PasswordChangeScucess />}
              ></Route>
              <Route
                path={investorviewdetails}
                element={<InvestorViewDetails />}
              ></Route>
              <Route path={investorprivacy} element={<InvestorPrivacy />}></Route>
              <Route
                path={investorprivacyview}
                element={<InvestorPrivacydownload />}
              ></Route>
              <Route path={startupprofile} element={<StartupProfile />}></Route>
              <Route path={watchlist} element={<Watchlist />}></Route>
              <Route path={accountsetting} element={<AccountSetting />}></Route>
              <Route path={investorprofile} element={<InvestorProfile />}></Route>
              <Route
                path={investmenthistory}
                element={<InvestmentHistory />}
              ></Route>
            </Route>
          </Routes>
        </Suspense>
        <ToastContainer
          position="top-center"
          style={{ fontSize: "20px" }}
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
      </Router>
    </>
  );
}

export default App;
